import React from "react";
import { useContent } from "../contexts/contentContext";

const LocalizedImage = (props) => {
  const [{ d_contents, curr_lang }] = useContent();
  const { imgKey, ...style } = props;
  // get image url from d_content by lang
  const imgSrc = d_contents?.[imgKey]?.[curr_lang] ? d_contents?.[imgKey]?.[curr_lang] : null;

  // console.log(curr_lang);
  // console.log(d_contents?.[imgKey]?.[curr_lang]);
  // console.log(imgSrc);
  if (imgSrc) {
    return <img src={imgSrc} {...style} />;
  } else {
    return "";
  }
};

export default LocalizedImage;
