import React from "react";
import LocalizedImage from "./LocalizedImage";
import styled from "styled-components";
import { useRoom } from "../contexts/roomContext";
import { useMediaQuery } from "react-responsive";

const Header = ({ headline, subtitle, themeColor }) => {
  // const [roomData, roomDispatch] = useRoom();
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)"
  });

  return (
    <>
      <LocalizedImage imgKey={"logo"} id="logo" style={{ marginBottom: isTablet ? "2rem" : "4rem" }} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Headline id="headline" color={themeColor}>
          {/* <LocalizedText textKey="headline" /> */}
          {headline}
        </Headline>
        <Subtitle id="subtitle">
          {/* <LocalizedText textKey="subtitle" /> */}
          {subtitle}
        </Subtitle>
      </div>
    </>
  );
};

const Headline = styled.div`
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 2rem;
  text-decoration: none;
  color: ${(props) => (props.color ? props.color : "#d31244")};

  @media only screen and (max-width: 1024px) {
    font-size: 1.125rem;
  }
`;

const Subtitle = styled.div`
  font-size: 1.25rem;
  margin-bottom: 3rem;
  @media only screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

export default Header;
