export const DEMO_MODE = window.config.n_dm;
export const DISPLAY_WAITING_TIME = window.config.n_dewt;
export const DEFAULT_ROOM_ID = window.config.n_drid;
export const endpoints = window.config.n_ep;
export const THEME_COLOR = window.config.n_c;
export const errorBackupDestinationUrl = window.config.n_error_backup_destination_url;
export const BAR_COLOR = window.config.n_bar_c ? window.config.n_bar_c : window.config.n_c;
export const ENABLE_RESTRICT_REFERRER = window.config.n_err;

export const DETECT_URL_TITLE = window.config.n_detect_url.title;
export const DETECT_URL_SUBTITLE = window.config.n_detect_url.subtitle;
export const DETECT_URL_UNDERLINE = window.config.n_detect_url.underline;

export const LIMIT_WAITING_TIME_DISPLAY = window.config.n_limit_waiting_time_display.on;
export const LIMIT_WAITING_TIME_DISPLAY_MIN = window.config.n_limit_waiting_time_display.limit;
export const EST_WAITING_TIME_SHOW_HOUR = window.config.n_est_show_hour;

export const CONTENT_API_FREQUENCY = window.config.n_capi_fry;

export const PRE_EVENT_CONGIG = window.config.n_pre_event
  ? window.config.n_pre_event
  : {
      display_bar: true,
      display_qp: true,
      display_ewt: true,
      display_upt: true
    };

export const POST_EVENT_CONFIG = window.config.n_post_event
  ? window.config.n_post_event
  : {
      display_bar: true,
      display_qp: true,
      display_ewt: true,
      display_upt: true
    };

export const DISPLAY_TICKET_DIV = window.config.n_display_ticket;
export const DISPLAY_TICKET_DIV_BEFORE_EVENT = window.config.n_display_ticket_bfe;
export const DISPLAY_QUEUE_NUMBER = window.config.n_dqn;
export const DISPLAY_ENQUEUE_NOTICE = window.config.n_d_enqueue_notice;
export const NOQ_STATUS_API = window.config.n_status_ep;
export const USE_FINAL_POPUP = window.config.n_use_final_popup;
export const STOPPED_REDIRECT = window.config.n_stopped_state_redirect;

export const WHITELIST_REFERRER = window.config.n_r;
export const REFERRER_FAIL_REDIRECT_URL = window.config.n_rf;
export const LAST_REFERRER_VALID_TIME = window.config.n_rvt;
export const LAST_MATCH_REFERRER_TIME_KEY = "n_last_r_match_time";
