import React from "react";
import LocalizedText from "./LocalizedText";
import styled from "styled-components";
import { useRoom } from "../contexts/roomContext";
import { useContent } from "../contexts/contentContext";
import { add, format } from "date-fns";
import { getJwtSessionId } from "../utils/sessionIdUtils";
import { EST_WAITING_TIME_SHOW_HOUR, LIMIT_WAITING_TIME_DISPLAY, LIMIT_WAITING_TIME_DISPLAY_MIN } from "../utils/variables";
import Popup from "./Popup";

const QueueDetail = ({ finalRenderState, token }) => {
  const [roomData, roomDispatch] = useRoom();
  const [contentData, ContentDispatch] = useContent();

  const jwt_session_id = getJwtSessionId(token);

  let qpos = <LocalizedText textKey="qpos_not_yet_available" />;
  if (finalRenderState?.showQueuePosition) {
    qpos = (
      <>
        {roomData?.queue_position || " "}
        <LocalizedText textKey="queue_unit" />
      </>
    );
  }

  let queue_time = <LocalizedText textKey="est_time_not_yet_available" />;
  if (finalRenderState?.showQueueEstTime) {
    queue_time = (
      <>
        {roomData?.est_time || " "}
        <LocalizedText textKey="minutes" />
      </>
    );

    let _hour = Math.floor(roomData?.est_time / 60);
    let _min = roomData?.est_time % 60;

    // display total est time in hour and mins
    if (EST_WAITING_TIME_SHOW_HOUR && roomData?.est_time >= 60) {
      queue_time =
        _min > 0 ? (
          // expected output ->  2 hours 30 mins
          <>
            {_hour} <LocalizedText textKey="hour" /> {_min}
            <LocalizedText textKey="minutes" />
          </>
        ) : (
          // expected output ->  2 hours
          <>
            {_hour} <LocalizedText textKey="hour" />
          </>
        );
    }

    // display more than xx hrs depends on {LIMIT_WAITING_TIME_DISPLAY} MODE
    if (LIMIT_WAITING_TIME_DISPLAY) {
      if (roomData?.est_time > LIMIT_WAITING_TIME_DISPLAY_MIN) {
        queue_time = !EST_WAITING_TIME_SHOW_HOUR ? (
          // expected output -> more than xx mins
          <>
            <LocalizedText textKey="more_than" />
            {LIMIT_WAITING_TIME_DISPLAY_MIN}
            <LocalizedText textKey="minutes" />
          </>
        ) : (
          // expected output -> more than xx hours
          <>
            <LocalizedText textKey="more_than" />
            {Math.floor(LIMIT_WAITING_TIME_DISPLAY_MIN / 60)}
            <LocalizedText textKey="hour" />
          </>
        );
      }
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {finalRenderState?.renderQueuePosition && (
        <RowBox>
          <div id="queue_position_hint" style={{ width: "50%" }}>
            <LocalizedText textKey="queuePositionHintText" />
          </div>

          <div id="queue_position" style={{ fontWeight: "500" }}>
            {qpos}
          </div>
        </RowBox>
      )}

      {finalRenderState?.renderQueueEstTime && (
        <RowBox>
          <div id="est_time_hint" style={{ width: "50%" }}>
            <LocalizedText textKey="estimatedTimeHintText" />
          </div>
          <div id="est_time" style={{ fontWeight: "500" }}>
            {queue_time}
          </div>
        </RowBox>
      )}

      {finalRenderState?.renderEnqueueNotice && (
        <EnqueueNotice id="enqueue_notice_div">
          <div id="enqueue_notice_p">
            <LocalizedText textKey="new_ticket_notice" />
          </div>
        </EnqueueNotice>
      )}

      {finalRenderState?.renderUpdateTime && (
        <>
          <RowBox style={{ marginBottom: 0 }}>
            <img
              style={{
                width: "12px",
                height: "10px",
                marginRight: "0.25rem",
                objectFit: "contain"
              }}
              src={"dot.png"}
            />
            <div id="last_update">
              <LocalizedText textKey="last_update" />
              {roomData?.lastUpdateTime && format(roomData?.lastUpdateTime, "hh:mm:ss a")}
            </div>
          </RowBox>
          <RowBox>
            <div id="next_update" style={{ marginLeft: "1rem" }}>
              <LocalizedText textKey="next_update" />
              {roomData?.nextRefreshTime && format(roomData?.nextRefreshTime, "hh:mm:ss a")}
            </div>
          </RowBox>
        </>
      )}

      {finalRenderState?.renderTicketNo && jwt_session_id && (
        <TicketNum id="ticket_div">
          <div id="ticket_session_id">
            <LocalizedText textKey="ticket_number" /> {jwt_session_id}
          </div>
        </TicketNum>
      )}
      {finalRenderState?.renderVerbo && (
        <div id="verbose_log">
          <pre> {contentData?.vMessage} </pre>
        </div>
      )}
      {roomData?.redirect && finalRenderState?.renderRedirectPopup && <Popup target={finalRenderState?.target} />}
      {/* {<Popup target={finalRenderState?.target} />} */}
    </div>
  );
};

const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  align-items: center;
  color: #515151;
`;

const EnqueueNotice = styled(RowBox)`
  font-size: 0.875rem;
  color: #2d3748;
  padding: 0.5rem;
  border: 1px solid #fc8181;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;

const TicketNum = styled(RowBox)`
  font-size: 0.75rem;
  color: #2d3748;
`;

export default QueueDetail;
