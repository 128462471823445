import Cookies from "js-cookie";
import urlparse from "url-parse";
import queryString from "query-string";
import qs from "qs";
import { DEMO_MODE, DEFAULT_ROOM_ID, ENABLE_RESTRICT_REFERRER, WHITELIST_REFERRER, REFERRER_FAIL_REDIRECT_URL, LAST_REFERRER_VALID_TIME, LAST_MATCH_REFERRER_TIME_KEY } from "./variables";

export function checkReferrer() {
	let referrer_check_success = false;
	let current_referrer = document.referrer;
	let last_match_time = parseInt(Cookies.get(LAST_MATCH_REFERRER_TIME_KEY), 10);

	for (let i = 0; i < WHITELIST_REFERRER.length; i++) {
		let aReferrer = WHITELIST_REFERRER[i];

		if (current_referrer.startsWith(aReferrer)) {
			referrer_check_success = true;
		}

		if (referrer_check_success) {
			Cookies.set(LAST_MATCH_REFERRER_TIME_KEY, `${Date.now()}`, {
				secure: false,
				expires: 30,
			});
		}
	}

	if (last_match_time) {
		let now = Date.now();
		let time_diff = now - last_match_time;

		if (time_diff <= LAST_REFERRER_VALID_TIME * 1000) {
			referrer_check_success = true;
		}
	}

	if (!referrer_check_success) {
		window.location.href = REFERRER_FAIL_REDIRECT_URL;
	}

	return referrer_check_success;
}

export function removeNoqParamFromURL(urlString) {
	let url = urlparse(urlString);
	if (url.query && url.query.length > 0) {
		url.query = url.query
			.split("&")
			.filter((e) => !e.startsWith("noq") && !e.startsWith("?noq"))
			.join("&");
	}
	return url.toString();
}

export function removeParamFromURL(urlString, query_param) {
	let url = urlparse(urlString);
	if (url.query && url.query.length > 0) {
		url.query = url.query
			.split("&")
			.filter((e) => !e.startsWith(query_param) && !e.startsWith("?" + query_param))
			.join("&");
	}
	return url.toString();
}

export function removeTokenFromURL() {
	let url = urlparse(removeParamFromURL(window.location.href, "noq_t"));
	let result = "";
	let query = "object" === typeof url.query ? queryString.stringify(url.query) : url.query;
	if (query) {
		result += "?" !== query.charAt(0) ? "?" + query : query;
	} else {
		result += "?";
	}
	if (url.hash) result += url.hash;
	window.history.replaceState({}, "", result);
}

export function sleep(timeOut) {
	return new Promise((resolve) => {
		setTimeout(resolve, timeOut);
	});
}

export function checkWhitelistedDestination(urlString, whitelist) {
	let checkResult = false;
	let url = urlparse(urlString);

	if (whitelist) {
		if (Array.isArray(whitelist) && whitelist.length > 0) {
			for (let i = 0; i < whitelist.length; i++) {
				let prefix = whitelist[i];
				if (url.hostname === prefix) {
					checkResult = true;
				}
			}
		} else {
			checkResult = true;
		}
	} else {
		checkResult = true;
	}

	if (url && url.hostname === "app.noq.com.hk") {
		// For App SDK
		checkResult = true;
	}

	return checkResult;
}

export function resolveRedirectURL(token, customRedirectURL, serverRedirectURL, whitelistedDomains) {
	// console.log({
	// 	token,
	// 	customRedirectURL,
	// 	serverRedirectURL,
	// 	whitelistedDomains,
	// });
	let target = customRedirectURL;
	if (!target || target === "") {
		target = serverRedirectURL;
	} else if (target && !checkWhitelistedDestination(target, whitelistedDomains)) {
		target = serverRedirectURL;
	}

	if (target) {
		let tmpUrl = urlparse(target);
		if (tmpUrl.query && tmpUrl.query.length > 0) {
			if (token && token !== "undefined") {
				tmpUrl.query = tmpUrl.query
					.split("&")
					.filter((e) => !e.startsWith("noq") && !e.startsWith("?noq"))
					.concat(`noq_t=${token}`)
					.join("&");
			} else {
				tmpUrl.query = tmpUrl.query
					.split("&")
					.filter((e) => !e.startsWith("noq") && !e.startsWith("?noq"))
					.join("&");
			}
		} else {
			if (token && token !== "undefined") {
				tmpUrl.query = `?noq_t=${token}`;
			}
		}

		if (window.location.hash) {
			tmpUrl += window.location.hash;
		}
		return tmpUrl;
	}

	return null;
}

export function checkCookiesSetted(key) {
	const alertMsg =
`您可能禁用了Cookies，以致無法排隊。請在您的瀏覽器設定中啟用Cookies。
You may have disabled cookies, queuing cannot be displayed properly. Please enable cookies in your browser.`
	let token = Cookies.get(key);
	if (!token) {
		alert(alertMsg)
		window.location.href = REFERRER_FAIL_REDIRECT_URL
	}
	return token
}

export function prepareBeforeWork() {
	let KEY = window.config.n_token_key + `-${DEFAULT_ROOM_ID}` || `noq-room-token-${DEFAULT_ROOM_ID}`;

	let referrer_check_success = true;
	try {
		Cookies.get(KEY);
	} catch (e) {
		console.error(e);
		alert("Cookie is required");
	}

	if (ENABLE_RESTRICT_REFERRER) {
		referrer_check_success = checkReferrer();
	}

	let { noq_t, noq_c, noq_v, noq_r } = qs.parse(window.location.search.slice(1));
	let verbose = false;
	if (noq_v === "true") {
		verbose = true;
	}

	if (noq_r) {
		noq_r = decodeURIComponent(noq_r);
		noq_r = removeNoqParamFromURL(noq_r);
	}

	if (noq_t && noq_t !== "undefined") {
		Cookies.set(KEY, noq_t, {
			secure: false,
			expires: 0.5,
		});
		checkCookiesSetted(KEY)
	}

	if (noq_t) {
		removeTokenFromURL();
	}
	if (DEMO_MODE && !noq_c) {
		noq_c = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : DEFAULT_ROOM_ID;
	}

	if (noq_c) {
		KEY = window.config.n_token_key + `-${noq_c}` || `noq-room-token-${noq_c}`;
	}

	return { noq_t, noq_c, noq_v, noq_r, verbose, KEY, referrer_check_success };
}
