import React from "react";
import { useContent } from "../contexts/contentContext";
import { DETECT_URL_UNDERLINE } from "../utils/variables";
import parse from "html-react-parser";

const LocalizedText = ({ textKey, urlify }) => {
	const [{ d_contents, curr_lang }] = useContent();
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	const displayText = d_contents?.[textKey]?.[curr_lang] ? d_contents?.[textKey]?.[curr_lang] : window.config.n_contents?.[textKey]?.[curr_lang];

	const urlRender = (text, url) => {
		let underline = " style='text-decoration: none; color:#4fd1c5'";
		// let aTagString = "";
		if (DETECT_URL_UNDERLINE) {
			underline = " style='text-decoration: underline; color:#4fd1c5'";
		}
		// aTagString = "<a" + underline + ' href="' + url + '" target="_blank">' + url + "</a>";
		return text.replace(urlRegex, "<a" + underline + ' href="' + url + '" target="_blank">' + url + "</a>");
	};

	if (urlify && displayText && displayText.match(urlRegex)) {
		const displayTextWithUrl = urlRender(displayText, displayText.match(urlRegex));
		// console.log(parse(displayTextWithUrl));

		return parse(displayTextWithUrl) || "";
	}

	return displayText || "";
};

export default LocalizedText;
