import React from "react";
import styled from "styled-components";
import { useRoom } from "../contexts/roomContext";
import { BAR_COLOR } from "../utils/variables";

const ProgressBarSection = ({ renderProgressBar }) => {
  const [roomData, roomDispatch] = useRoom();

  // running progress
  let goal_percent = 0;
  if (roomData?.est_time) {
    goal_percent = 1.0 - roomData?.est_time / 60;
    if (goal_percent <= 0) goal_percent = 0;
  }

  return (
    <div id="progress_bar_div" style={{ display: `${renderProgressBar ? "flex" : "none"}`, marginBottom: "3rem", alignItems: "center" }}>
      <ProgressBarBase id="progress_bar">
        <ProgressRunningBar bgcolor={BAR_COLOR} width={`${goal_percent * 100}%`} />
      </ProgressBarBase>
      <img
        id="final_icon"
        src={"/finalline.png"}
        alt=""
        style={{
          width: "40px",
          height: "44px"
        }}
      />
    </div>
  );
};

const ProgressBarBase = styled.div`
  position: relative;
  height: 0.75rem;
  flex: 1 1;
  border-radius: 9999px;
  background: #f7fafc;
  margin-right: 1rem;
`;

const ProgressRunningBar = styled(ProgressBarBase)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  background: ${(props) => (props.bgcolor ? props.bgcolor : "#f7fafc")};
  width: ${(props) => props.width};
`;

export default ProgressBarSection;
